import "./about.styles.scss";
import { Link } from "react-router-dom";
const AboutSection = () => {
  return (
    <div className="about-section">
      <h1 className="hello-h"> Hello! </h1>
      <div className="about-info">
        {" "}
        My Name is Joshua. I am currently a software engineer living in San
        Diego, California. I have had a passion for coding ever since I was
        exposed to programming in college in a C++/C course. I have coded
        numerous amounts of software during my 4 years at Arizona State
        University as a computer science undergrad and 1 year professionally as
        a software engineer. I always attempt to expand upon my skill set through work opportunities and self practice while refining and perfecting skills I already have. {" "}
      </div>
      <div className="tech-list">
        <h3>Technologies</h3>
        <ul>
          <li>
            {" "}
            <span>-</span> React Js
          </li>
          <li>
            <span>-</span> React Native
          </li>
          <li>
            <span>-</span> Node Js
          </li>
          <li>
            <span>-</span> Next Js
          </li>
          <li>
            <span>-</span> Angular
          </li>
          <li>
            <span>-</span> Python
          </li>
        </ul>
      </div>
      <p className="resume-request">Resume Available Upon Request</p>
      <Link className="button-c" to="/contact">
        <button className="btn">Contact</button>
      </Link>
    </div>
  );
};
export default AboutSection;
