import React from "react";
import avatar from "../../img/avatar.png";
import { NavLink } from "react-router-dom";
import "./navbar.styles.scss";
import { useAppContext } from "../../context/appContext";
import favicon from "../../img/fav-icon.png";

function NavBar() {
  const { hideNav } = useAppContext();

  const navClickHandler = () => {
    hideNav();
  };
  return (
    <div className="NavBar">
      <nav className="nav">
        <div className="profile">
          <div className="name-container">
            <div className="joshua-container">
              <img src={favicon} alt="" />
            </div>
            <h2 className="web-developer">Software Engineer</h2>{" "}
          </div>
        </div>
        <ul className="nav-items">
          <li className="nav-item">
            <NavLink
              to="/"
              exact
              activeClassName="active"
              onClick={navClickHandler}
            >
              Home
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/about"
              exact
              activeClassName="active"
              onClick={navClickHandler}
            >
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/portfolio"
              exact
              activeClassName="active"
              onClick={navClickHandler}
            >
              Portfolio
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/contact"
              exact
              activeClassName="active"
              onClick={navClickHandler}
            >
              Contact
            </NavLink>
          </li>
        </ul>
        <footer className="footer">
          <p>@2023 Joshua Godinez</p>
        </footer>
      </nav>
    </div>
  );
}

export default NavBar;
